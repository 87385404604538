import React, { useLayoutEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { graphql, useStaticQuery, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Document from "../../components/svgs/Document/Document";
import {
  wrap,
  link,
  svg,
  text,
  titlewrap,
  searchinput,
} from "./News.module.scss";
import AnchorSvg from "../../components/svgs/Anchor/Anchor";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Card from "@material-ui/core/Card";
import useLang from "../../contexts/Language/LanguageContext";
import useWrapRef from "../../contexts/WrapRef/WrapRefContext";
import { format } from "date-fns";

export default function News() {
  const lang = useLang();
  const wrapCtx = useWrapRef();
  const [search, setSearch] = useState("");
  useLayoutEffect(() => {
    if (wrapCtx && wrapCtx.wrapRef && wrapCtx.wrapRef.current) {
      wrapCtx.wrapRef.current.scrollTo(0, 0);
    }
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allContentfulProject(
        sort: { fields: publishedDate, order: DESC }
        filter: { isNews: { eq: true } }
      ) {
        edges {
          node {
            title
            titleen
            slug
            publishedDate
            id
            description {
              raw
              references {
                __typename
                ... on ContentfulAsset {
                  contentful_id
                  title
                  gatsbyImageData
                  file {
                    url
                    contentType
                  }
                }
              }
            }
            descriptionen {
              raw
              references {
                __typename
                ... on ContentfulAsset {
                  contentful_id
                  title
                  gatsbyImageData
                  file {
                    url
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <>
            {node.data.target.gatsbyImageData && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GatsbyImage
                  style={{ maxWidth: "100%", maxHeight: "400px" }}
                  image={node.data.target.gatsbyImageData}
                  alt={node.data.target.title}
                />
              </div>
            )}
            {node.data.target.file.contentType === "application/pdf" && (
              <div className={wrap}>
                <div className={svg}>
                  <Document />
                </div>
                <a
                  className={link}
                  href={node.data.target.file.url}
                  download
                  target="_blank"
                >
                  {node.data.target.title}
                </a>
              </div>
            )}
            {node.data.target.file.contentType === "video/mp4" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <iframe
                  src={node.data.target.file.url}
                  height="100%"
                  frameBorder="0"
                  scrolling="no"
                  title={node.data.target.title}
                  allowFullScreen={true}
                />
              </div>
            )}
          </>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        return (
          <div className={wrap}>
            <a
              className={link}
              target="_blank"
              rel="noreferrer"
              href={node.data.uri}
            >
              {node.content[0].value}
            </a>
          </div>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return node.content.length > 1 ? (
          <div>{children}</div>
        ) : (
          <p className={text}>{children}</p>
        );
      },
    },
  };
  return (
    <>
      <div className={titlewrap}>
        <h1>{lang === "mne" ? "Novosti" : "News"}</h1>
        <input
          className={searchinput}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          placeholder={lang === "mne" ? "Pretraga..." : "Search..."}
        />
      </div>
      <div role="list">
        {data.allContentfulProject.edges
          .filter((e) => {
            if (lang === "mne")
              return e.node.title?.toLowerCase().includes(search.toLowerCase());
            return e.node.titleen?.toLowerCase().includes(search.toLowerCase());
          })
          .map((edge) => (
            <div role="listitem" key={edge.node.id}>
              <Card style={{ marginBottom: "20px", padding: "20px" }}>
                <h2 style={{ color: "black", marginBottom: "0px" }}>
                  {lang === "mne"
                    ? edge.node.title
                    : edge.node.titleen
                    ? edge.node.titleen
                    : edge.node.title}
                </h2>
                <p
                  style={{ fontSize: "10px", color: "gray", marginTop: "2px" }}
                >
                  {format(new Date(edge.node.publishedDate), "dd.MM.yyyy")}
                </p>
                {renderRichText(
                  lang === "mne"
                    ? edge.node.description
                    : edge.node.descriptionen
                    ? edge.node.descriptionen
                    : edge.node.description,
                  options
                )}
                <div>
                  <Link to={edge.node.slug}>
                    <ExitToAppIcon
                      style={{
                        color: "black",
                        width: "40px",
                        height: "40px",
                        display: "block",
                        marginLeft: "auto",
                      }}
                    />
                  </Link>
                </div>
              </Card>
            </div>
          ))}
      </div>
    </>
  );
}
